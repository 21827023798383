// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-api-reference-jsx": () => import("./../src/pages/api-reference.jsx" /* webpackChunkName: "component---src-pages-api-reference-jsx" */),
  "component---src-pages-for-developers-jsx": () => import("./../src/pages/for-developers.jsx" /* webpackChunkName: "component---src-pages-for-developers-jsx" */),
  "component---src-pages-glossary-jsx": () => import("./../src/pages/glossary.jsx" /* webpackChunkName: "component---src-pages-glossary-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-release-notes-jsx": () => import("./../src/pages/release-notes.jsx" /* webpackChunkName: "component---src-pages-release-notes-jsx" */),
  "component---src-pages-site-map-jsx": () => import("./../src/pages/site-map.jsx" /* webpackChunkName: "component---src-pages-site-map-jsx" */),
  "component---src-templates-category-jsx": () => import("./../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-doc-jsx": () => import("./../src/templates/doc.jsx" /* webpackChunkName: "component---src-templates-doc-jsx" */)
}

