module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PQC59L","includeInDevelopment":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":690},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"1CjnBNPybWzyv10ssZRxdwmb8vrYF4yj","devKey":"QaZKJjbUWrCqNO0ObRrF64qDRVE40ZIr","trackPage":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SendGrid Documentation","short_name":"SendGrid Documentation","description":"Learn how to get started using SendGrid. Integrate in minutes, test for free, and get access to documents to help solve your email deliverability problems.","start_url":"/docs","display":"minimal-ui","icons":[{"src":"/brand/sg-mark.png","sizes":"771x724","type":"image/png"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
